<template>
  <div>
    <b-card title="Liste des navires">
      <div class="text-right mb-3">
        <b-select
          v-model="perPage"
          :options="pageOptions"
          style="width: fit-content"
        />
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune demande enrégistrée"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
          <div class="d-flex justify-content-center">
            <strong>Chargement en cours...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(merchandise_tonnage)="data">
          {{ data.item.merchandise_tonnage }} T
        </template>
        <template v-slot:cell(action)="row">
          <span class="whitespace-no-wrap">
            <feather-icon
              :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
              svg-classes="w-5 h-5 hover:text-info stroke-current"
              class="text-info"
              @click="row.toggleDetails"
            />
          </span>
        </template>
        <template #row-details="row">
          <b-row>
            <b-col sm="6">
              <b-card title="Détails Entreprise">
                <table class="w-100">
                  <tr class="w-100">
                    <td class="w-50">
                      Représentant
                    </td>
                    <td class="w-50">
                      {{ row.item.full_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Téléphone
                    </td>
                    <td class="w-50">
                      {{ row.item.phone_number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Email
                    </td>
                    <td class="w-50">
                      {{ row.item.email }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Pays
                    </td>
                    <td class="w-50">
                      {{ row.item.country }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Ville
                    </td>
                    <td class="w-50">
                      {{ row.item.city }}
                    </td>

                  </tr>
                </table>
              </b-card>
            </b-col>
            <b-col sm="6">
              <b-card title="Détails de la demande">
                <table class="w-100">
                  <tr class="w-100">
                    <td class="w-50">
                      Date de création
                    </td>
                    <td class="w-50">
                      {{ row.item.created_at }}
                    </td>
                  </tr>
                </table>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      items: [],
      loaded: false,
      totalItems: null,
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'enterprise', label: 'Entreprise' },
        { key: 'ship_nature.title', label: 'Nature Navire' },
        { key: 'operation_type.name', label: 'Type de cotation' },
        { key: 'merchandise_nature', label: 'Marchandise' },
        { key: 'merchandise_tonnage', label: 'Poids' },
        { key: 'action', label: 'Action', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 15, 20],
    }
  },

  computed: {
    ...mapGetters({
      token: 'token',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllQuotations()
      },
    },
    currentPage() {
      this.getAllQuotations()
    },
    perPage() {
      this.getAllQuotations()
    },
  },
  methods: {
    async getAllQuotations() {
      this.loaded = true
      this.items = await this.$http.get(`/api/quotation/find-all-paged/${this.currentPage}/${this.perPage}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        this.loaded = false
        this.totalItems = res.data.total_count
        return res.data.items
      })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Demande de cotation',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
  },
}
</script>
